<template>
  <div class="manage-review">
    <template v-if="!state.review.loaded || state.review.list.length">
      <ReviewTagBar
          classType="detail"
          :url="`/api/project/${$route.params.projectSeq}/review/tags`"
          :overflow="true"
          :skeleton="!state.review.loaded"
      />
      <div class="list" ref="contentRef">
        <ReviewCard
            v-for="(r,idx) in state.review.list" :key="idx"
            :skeleton="!state.review.loaded"
            :builderSeq="project.openId"
            :content="r.content"
            :createDate="r.createDate"
            :delFlag="r.delFlag"
            :hideInvestorNameYn="r.hideInvestorNameYn"
            :reviewId="r.id"
            :replies="r.replies"
            :memberSeq="r.memberSeq"
            :memberName="r.memberName"
            :photos="r.photos"
            :rewardTitle="r.rewardTitle"
            :selectedTags="r.selectedTags"
            :updateDate="r.updateDate"
            :blindFlag="r.blindFlag"
        />
      </div>
      <PaginationJpa :pageable="state.review.pageable" :total="state.review.total" :change="load" v-if="state.review.pageable.size"/>
    </template>
    <NoData text="등록된 후기가 없습니다." v-else/>
  </div>
</template>

<script>
import mixin from "@/scripts/mixin";
import {defineComponent, reactive, ref} from "@vue/composition-api";
import lib from "@/scripts/lib";
import http from "@/scripts/http";
import router from "@/scripts/router";
import ReviewCard from "@/components/ReviewCard";
import PaginationJpa from "@/components/PaginationJpa";
import NoData from "@/components/NoData";
import ReviewTagBar from "@/components/ReviewTagBar";

function Component(initialize) {
  this.name = "pageManageReview";
  this.initialize = initialize;
}

export default defineComponent({
  components: {ReviewTagBar, NoData, PaginationJpa, ReviewCard},
  mixins: [mixin],
  props: {
    project: Object
  },
  setup() {
    const component = new Component(() => {
      load();
    });

    const state = reactive({
      review: {
        loaded: false,
        pageable: {
          page: 0,
          size: 0
        },
        total: null,
        list: [],
      },
      args: {
        page: 0,
        size: 20,
        sort: "createDate,desc",
      }
    });

    const contentRef = ref();

    const load = (page, scrollTo) => {
      state.review.loaded = false;
      state.review.list = [];
      state.args.page = page || 0;

      if (contentRef.value && !scrollTo) {
        window.scrollTo({
          top: contentRef.value.getBoundingClientRect().top + window.scrollY - lib.getHeaderHeight() - 140,
          left: 0,
          behavior: "smooth"
        });
      }

      for (let i = 0; i < 5; i += 1) {
        state.review.list.push({
          skeleton: true,
          content: "Wait a moment",
          createDate: "0000-00-00 00:00",
          memberName: "Wait",
          selectedTags: "wait,wait,wait,wait,wait",
        });
      }

      http.get(`/api/project/${router.app.$route.params.projectSeq}/reviews`, state.args).then(({data}) => {
        state.review.loaded = true;
        state.review.list = data.body.content;
        state.review.pageable = data.body.pageable;
        state.review.total = data.body.total;
      });
    };

    return {component, state, contentRef, load};
  }
});
</script>

<style lang="scss" scoped>

</style>